*{
    margin: 0px;
    padding: 0px;
}

body{
    font-family: arial;
}

hr{
    height: 7.5px;
    color: grey;
    background-color: grey;
    margin: 1px 0px 2px 0px;
    border: 0;
}

.loading{
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px !important;
    height: 120px !important;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#main{
    margin-bottom: 1em;
    padding-bottom: 1em;
}

/* .mainStatus{
    color: white;
    font-weight: bold;
    width: 100vw;
    text-align: center;
    display: table;

    font-size: 48pt;
} */

#mainStatus{
    color: white;
    font-weight: bold;
    width: 100vw;
    text-align: center;
    display: table;

    font-size: 48pt;

    -moz-box-shadow: 0 1px 5px 0px gray;
    -webkit-box-shadow: 0 1px 5px 0px gray;
    box-shadow: 0 1px 5px 0px gray;

    /* z-index: 1;
    position: fixed;
    top: 0vh; */
}

.psa{
    width: 100vw;
    text-align: center;
    display: table;

    padding-top: 2vh;
    padding-bottom: 2vh;

    font-size: 24pt;
    background-color: #d83d42;
}

.status{
    overflow: hidden;
    position: fixed;
	top: 0;
	left: 0;
	transition: height 0.1s;
}

.messages-scroll{
    padding-top: 240px;
    scroll-behavior: auto;
    overflow-y: scroll;
}

.status-width{
    width: 100vw;
    text-align: center;
    display: table;

    font-size: 48pt;

    -moz-box-shadow: 0 1px 5px 0px gray;
    -webkit-box-shadow: 0 1px 5px 0px gray;
    box-shadow: 0 1px 5px 0px gray;
}

.status-full{
    height: 100vh;
    width: 100vw;
    text-align: center;
    display: table;

    font-size: 48pt;
}

.status-height{
    height: 240px; /* !important; */
}

@media only screen and (max-height: 400px){
    .status-height{
        height: 45vh;
    }

    .messages-scroll{
        padding-top: 45vh;
    }
}

@media only screen and (max-width: 320px) and (max-height: 320px) {
    .status-height { height: 100vh; }
}

@media only screen and (min-width: 2048px) and (min-height: 1080px) {
    .status-height { height: 30vh; }

    .status-width { font-size: 12em; }
}

@media only screen and (min-width: 2100px) and (min-height: 1100px) {
    .status-height { height: 40vh; }

    .status-width { font-size: 16em; }
}

@media only screen and (min-width: 3000px) and (min-height: 2000px) {
    .status-height { height: 40vh; }

    .status-width { font-size: 16em; }
}

@media only screen and (max-width: 450px){
    .unavailable-font{
        font-size: 28pt !important;
    }
}

.size-zero, .hidden{
    height: 0;
    width: 0;
    margin: 0 !important;
    padding: 0 !important;
}

.size-max{
    height: 100vh;
    width: 100vw;
}

.bold{
    font-weight: bold;
}

.padding-none{
    padding: 3vh 5vw 0vh;
    width: 90vw;
}

.center{
    width: auto;
    text-align: center !important;
}

.center-status{
    vertical-align: middle;
    display: table-cell;
}

.empty{
    color: #646464 !important;
}

.font-36{
    font-size: 36pt !important;
    font-weight: 600;
}

.font-12{
    font-size: 9pt;
    font-weight: 500;
}

svg{
    height: 27px;
    margin-top: 0px;
    margin-bottom: -5px;
}

@media (max-width: 300px) {
    .right{ float: none; }
}

.right{
    float: right;
}

.margin{
    margin: 5px;
}

.margin-bottom{
    margin-bottom: 2.5vh;
}

.good, .none{
    background-color: #339966;
}

.minor{
    background-color: #DBAB09;
}

.major{
    background-color: #E25D10;
}

.critical{
    background-color: #DC3545;
}

.maintenance{
    background-color: #0366d6;
}

@media only screen and (min-width: 351px) and (max-width: 450px){
    .maintenance{
        font-size: 36pt;
        font-weight: 500;
    }
}

@media only screen and (min-width: 251px) and (max-width: 350px){
    .maintenance{
        font-size: 30pt;
        font-weight: 500;
    }
}

@media only screen and (min-width: 151px) and (max-width: 250px){
    .maintenance{
        font-size: 20pt;
        font-weight: 500;
    }
}

.unavailable{
    background-color: #4F93BD;
}

.error{
    background-color: #646464;
}

.status-color{
    color: white;
}

.black{
    color: black !important;
}

/* #messages{
    top: 2vh;
    bottom: 2vh;
    position: relative;
} */

.message-general{
    height: auto;
}

.message-first{
    padding-top: 1.5em;
}

.message-last{
    padding-bottom: 0.5em;
}

.messages{
    /* margin: 1.5em 0px 1.5em 0px; */
    padding: 0px;
}

@media only screen and (min-width: 2100px) and (min-height: 1100px) {
    .messages { margin: 3em 0px; }
}

.date{
    font-size: 9pt !important;
}

.status-box{
    height: 45px;
    font-size: 14pt;

    color: white;
    display: table !important;
}

.text-margin{
    width: 90vw;
    font-size: 11pt;
    margin: 1em 2.5vw;
}

.none-message, .good-message{
    width: 120px;
    background-color: #339966;
}

.minor-message{
    width: 200px;
    background-color: #F1C40F;
}

.major-message{
    width: 280px;
    background-color: #FF9900;
}

.critical-message{
    width: 360px;
    background-color: #990000;
}

.error-message{
    width: 120px;
    background-color: #646464;
}

@media only screen and (max-width: 850px) {
  .good-message{ width: 20vw; }

  .minor-message{ width: 40vw; }

  .major-message{ width: 60vw; }

  .critical-message{ width: 80vw; }

  .error-message{ width: 50vw; }

  .text-margin{ margin: 3vh 4vw; }
}

@media only screen and (max-width: 400px) {
    .text-margin{ margin: 3vh 7vw; }
}

@media only screen and (max-width: 320px) and (max-height: 320px) {
    .messages{ display: none; height: 0vh; }
}

@media (min-width: 2048px) and (min-height: 1080px){
    .good-message{ width: 20vw; }

    .minor-message{ width: 40vw; }

    .major-message{ width: 60vw; }

    .critical-message{ width: 80vw; }

    .error-message{ width: 50vw; }

    .status-box{ height: 2em; font-size: 2.5em; }

    .text-margin{ font-size: 2.5em; }

    .date{ font-size: 0.75em !important; }
}

@media (min-width: 2100px) and (min-height: 1100px){
    .status-box{ height: 1.75em; font-size: 3.5em; }

    .text-margin{ font-size: 3em; }
}

@media (min-width: 3000px) and (min-height: 2000px){
    .status-box{ height: 1.75em; font-size: 5em; }

    .text-margin{ font-size: 4em; }
}

.message-status{
    width: 100%;
    padding-right: 0.5em;
    vertical-align: middle;
    display: table-cell !important;
}

.message-error{
  padding: 3vh 7.5vw 0vh 7.5vw !important;
  color: #646464;
}

/* one item */
.status-height-comp:first-child:nth-last-child(1) {
    height: 100vh;
}

/* two items */
.status-height-comp:first-child:nth-last-child(2),
.status-height-comp:first-child:nth-last-child(2) ~ .status-height-comps {
    height: 50vh;
}

/* three items */
.status-height-comp:first-child:nth-last-child(3),
.status-height-comp:first-child:nth-last-child(3) ~ .status-height-comps {
    height: 33.3333vh;
}

/* four items */
.status-height-comp:first-child:nth-last-child(4),
.status-height:first-child:nth-last-child(4) ~ .status-height-comps {
    height: 25vh;
}

/* five items */
.status-height-comp,
.status-height-comp:first-child:nth-last-child(5),
.status-height-comp:first-child:nth-last-child(5) ~ .status-height-comps {
    height: 20vh;
}

.no-shadow {
    -moz-box-shadow:0 0px 0px 0 gray;
    -webkit-box-shadow:0 0px 0px 0 gray;
    box-shadow:0 0px 0px 0 gray;
}
